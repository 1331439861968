import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import axios from 'axios';
import ABI from './ABI.json';
import VAULTABI from './VAULTABI.json';
import { NFTCONTRACT, STAKINGCONTRACT, polygonscanapi, moralisapi } from './config';
import Web3Modal from "web3modal";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";

var account = null;
var contract = null;
var vaultcontract = null;
var web3 = null;
const gasOptions = { gasPrice: 25854963264, gasLimit: 500000 };

const moralisapikey = "JwcyI3kFcZIx9M9Psom0b0d2ahdb9VxmSs80MnYU43bbwX803Jufh0XzqYKbXzyg";
const polygonscanapikey = "JAR6HQQDTVZ3UQIHCUFHJ7MV2M5E6V2FFE";

const providerOptions = {
};


const web3Modal = new Web3Modal({
    network: "mainnet",
    theme: "dark",
    cacheProvider: false,
    providerOptions
});

class App extends Component {
    constructor() {
        super();
        this.state = {
            balance: [],
            rawearn: [],
        };
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    handleNFT(nftamount) {
        this.setState({ outvalue: nftamount.target.value });
    }

    async componentDidMount() {

        // Adding Google Analytics
        ReactGA.initialize('UA-236874074-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        //   Adding Facebook Pixels
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('371285161139798');
                ReactPixel.pageView();
            });

        await axios.get((polygonscanapi + `?module=stats&action=tokensupply&contractaddress=${NFTCONTRACT}&apikey=${polygonscanapikey}`))
            .then(outputa => {
                this.setState({
                    balance: outputa.data
                })
                console.log(outputa.data)
            })
        let config = { 'X-API-Key': moralisapikey, 'accept': 'application/json' };
        await axios.get((moralisapi + `/nft/${NFTCONTRACT}/owners?chain=polygon&format=decimal`), { headers: config })
            .then(outputb => {
                const { result } = outputb.data
                this.setState({
                    nftdata: result
                })
                console.log(outputb.data)
            })
    }

    render() {
        async function connectwallet() {
            var provider = await web3Modal.connect();
            web3 = new Web3(provider);
            await provider.request({ method: 'eth_requestAccounts' });
            var accounts = await web3.eth.requestAccounts();
            account = accounts[0];
            document.getElementById('wallet-address').textContent = account;
            contract = new web3.eth.Contract(ABI, NFTCONTRACT);
            vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
            var getstakednfts = await vaultcontract.methods.tokensOfOwner(account).call();
            document.getElementById('yournfts').textContent = getstakednfts;
            var getbalance = new BigNumber(await vaultcontract.methods.balanceOf(account).call());
            document.getElementById('stakedbalance').textContent = getbalance.toFixed();
        
            const arraynft = Array.from(getstakednfts.map(Number));
            const tokenid = arraynft.filter(Number);
            var rwdArray = [];
            tokenid.forEach(async (id) => {
                var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
                var array = Array.from(rawearn.map(item => new BigNumber(item)));
              
                array.forEach(async (item) => {
                  var earned = item.toPrecision(22).split('.')[0];
                  var earnedrwd = new BigNumber(earned).div(new BigNumber(10).pow(18));
                  rwdArray.push(earnedrwd);
                });
            });
        
            function delay() {
                return new Promise(resolve => setTimeout(resolve, 300));
            }
        
            async function delayedLog(item) {
                await delay();
                var sum = item.reduce((a, b) => a.plus(b), new BigNumber(0));
                var formatsum = sum.toFixed(2);
                document.getElementById('earned').textContent = formatsum;
            }
        
            async function processArray(rwdArray) {
                for (const item of rwdArray) {
                    await delayedLog(item);
                }
            }
        
            return processArray([rwdArray]);
        }
        

        async function enable() {
            contract.methods.setApprovalForAll(STAKINGCONTRACT, true).send({ from: account, ...gasOptions });
        }
        async function rewardinfo() {
            var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
            const arraynft = Array.from(rawnfts.map(Number));
            const tokenid = arraynft.filter(Number);
            var rwdArray = [];
          
            for (const id of tokenid) {
              var rawearn = await vaultcontract.methods.earningInfo(account, [id]).call();
              var array = Array.from(rawearn.map(item => new BigNumber(item)));
              array.forEach(item => {
                var earned = item.toPrecision(22).split('.')[0];
                var earnedrwd = new BigNumber(earned).div(new BigNumber(10).pow(18));
                rwdArray.push(earnedrwd);
              });
            }
        
            async function delayedLog(item) {
                var sum = rwdArray.reduce((a, b) => a.plus(b), new BigNumber(0));
                var formatsum = sum.toFixed(2);
                document.getElementById('earned').textContent = formatsum;
            }
        
            return delayedLog(rwdArray);
        }
        
        return (
            <div className="container py-2 ">
                <div className="app ">
                    <div className="">
                        {/* <input id="connectbtn" type="button" className="connectbutton" onClick={connectwallet} style={{ backgroundColor: "#26A7DF", className: "nav-item d-flex align-content-center flex-wrap" }} value="Connect Your Wallet" /> */}
                    </div>
                    <div className=', '>
                        <div className='col, py-4'>
                            <div className=''>
                                <div>
                                    <div className="row">
                                        <div>
                                            <h1 className="pt-3 MStakingP" >MetaBadges GEN1</h1>
                                            {/* <h1 className="pt-3" style={{ fontWeight: "30", color: "#ffffff", fontFamily: "Avenir" }}>MetaBadge Staking Platform</h1> */}
                                        </div>
                                        <h6 style={{ fontWeight: "800", color: "#252F5C", fontFamily: "Avenir" }}>Your Wallet Address</h6>
                                        <div className="pb-1" id='wallet-address' style={{
                                            fontSize: "16px",
                                            color: "#4AC1E8",
                                            fontWeight: "800",
                                            // textShadow: "1px 1px 1px white",
                                        }}>
                                            <Button id="connectbtn" type="button" className="btn mt-1"
                                            style={{ backgroundColor: "#D53790", borderRadius: "100px", borderColor: "#D53790", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                             onClick={connectwallet} htmlFor="floatingInput">Connect your wallet to start</Button>
                                            {/* <label type="button" onClick={connectwallet} htmlFor="floatingInput">Please Connect Your Wallet</label> */}
                                            <Button className="btn mt-2" onClick={enable}
                                    style={{ backgroundColor: "#4AC1E8", borderRadius: "100px", borderColor: "#4AC1E8", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                >Authorize Your Wallet</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col mt-3'>
                        <div className='nftstaker border-0 '>
                            <div style={{ fontFamily: "Avenir" }} >
                                <h2 className="pt-2" style={{ fontFamily: "Avenir", borderRadius: '14px', fontWeight: "800", color: "#252F5C", fontSize: "30px" }} id="Vault">Staking Vault </h2>
                                {/* <h6 style={{ fontWeight: "400", color: "#252F5C", fontFamily: "Avenir", fontSize: "18px" }}>First time staking?</h6> */}
                                <div className="row mt-5 px-3 pt-1">
                                    <div className="col-sm-4 col-center-block">
                                        <div className="" style={{ borderRadius: "25px" }}>
                                            {/* <h5 style={{ color: "#252F5C", fontSize: "15px", fontWeight: '300', paddingTop: 15 }}>OSIS Vault</h5> */}
                                            <table className='table mb-5 px-3 table-dark wrap-nft'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontSize: "0px", border: "transparent", background: 'transparent', paddingTop: 0, paddingBottom: 0 }}>
                                                            <span style={{ backgroundColor: "#252F5C", fontSize: "0px", color: "#D53790", fontWeight: "80   0", }} id='yournfts'></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontSize: "21px", fontWeight: "800", color: "#252F5C", border: "transparent", background: 'transparent', paddingTop: 1, paddingBottom: 1 }}>Total Staked NFTs:<br></br>
                                                            <span style={{ backgroundColor: "#ffffff00", fontSize: "21px", color: "#D53790", fontWeight: "800", }} id='stakedbalance'>Total Staked</span>
                                                        </td>
                                                    </tr>
                                                    {/* <tr> */}
                                                    {/* <td style={{ fontSize: "19px", background: 'transparent' }}> */}
                                                    {/* <Button onClick={unstakeall}
                                                            style={{ backgroundColor: "#D53790", boxShadow: "1px 1px 5px #4ac0e7", color: "#ffffff", fontFamily: "Avenir"  }}className='mb-4'
                                                            >Unstake All</Button> */}
                                                    {/* </td> */}
                                                    {/* </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <img style={{ width: 320, height: 200 }} className=" col-sm-2 col-center-block mb-3" src="gen1.png" alt="" />
                                    <div className="col-sm-4 col-center-block">
                                        <div className='' style={{ color: "#252F5C", fontSize: '21px', fontWeight: '800', fontFamily: "Avenir" }}>Earned MetaBadges Coins:
                                            <div style={{ border: "transparent", background: "transparent" }} >
                                            </div>
                                            <div className='mergediv' id='earned' style={{ color: "#D53790", fontSize: '21px', fontWeight: '800', }}><p >Earned</p>
                                            </div>
                                            <div className='mergediv'>
                                                <button style={{ border: "none", background: "transparent" }} >
                                                    <img src="refresh.png" width="" height="30" background="black" border="transparent" alt="" onClick={rewardinfo} />
                                                </button>
                                            </div>
                                            <div style={{ color: "#252F5C", fontSize: "15px", fontWeight: '300', paddingTop: 0 }}>(unstake to claim rewards)</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-4 pt-2">
                                    <div className="header">
                                        <div className='col mt-5'></div>
                                        <div style={{ fontSize: '30px', color: "#252F5C", fontWeight: "800" }}>Staking Pool Rewards</div>
                                        <h1>
                                        </h1>
                                        <table style={{}} className='table px-3 mt-3 table-bordered table-light' >
                                            <thead className='thead-light'>
                                                <tr className='collectionmenu'>
                                                    <th scope="col">Collection</th>
                                                    <th scope="col">Rewards Per Day</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Standard MetaBadge</td>
                                                    <td className="amount" data-test-id="rewards-summary-ads">
                                                        <strong><span className="amount rewardsmetabadges">100</span></strong>&nbsp;<span className="currency">MetaBadge Coins per day</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Rare / Super Rare MetaBadge</td>
                                                    <td className="amount " data-test-id="rewards-summary-ac">
                                                        <strong> <span className="amount rewardsmetabadges">200</span></strong>&nbsp;<span className="currency">MetaBadge Coins per day</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Button className="btn" href="/OSIS_Rewards.pdf" target="_blank" rel="noreferrer"
                                            style={{ backgroundColor: "#4AC1E8", borderRadius: "100px", borderColor: "#4AC1E8", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                        >OSIS Rewards List</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col mt-1 ' id="NFT">
                    <div className='col'>
                            <h1 className=' mb-3 mt-5 ' style={{ fontFamily: "Avenir", fontSize: '30px', color: "#252F5C", fontWeight: "800" }}>Gen1 Portal</h1>
                            <CookieConsent
                                location="bottom"
                                buttonText="I accept"
                                expires={0}
                                cookieName="GDPR Compliance"
                                debug
                                acceptOnScroll
                                declineButton
                                flipButtons
                                style={{ background: '#ffffff', color: '#252F5C', justifyContent: 'center' }}
                                buttonStyle={{
                                    marginTop: '0px',
                                    background: '#4AC1E8',
                                    color: '#FFFFFF',
                                }}
                                contentStyle={{ flex: '0 0 100%', textAlign: 'center' }}
                            // onAccept={({ acceptedByScrolling }) => {
                            //     if (acceptedByScrolling) {
                            //         // triggered if user scrolls past threshold
                            //         alert('Accept to continue scrolling');
                            //     } else {
                            //         alert('Accept to continue scrolling');
                            //     }
                            // }}
                            >This website uses cookies to enhance the user experience.<br></br>
                                {/* <span style={{ fontSize: "12px" }}>©Copyright 2024 | Apotheosis | All Rights Reserved</span>{' '} */}
                            </CookieConsent>
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}
export default App;
