import React from "react";
import "./App.css";
import { Nav, Navbar } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const Styles = styled.div`
  .navbar {
    background-color: #172633;
  }

  .navbar-brand,
  .navbar-nav .nav-item .nav-link,
  .navbar-toggler,
  .navbar-toggler-icon {
    color: #fff;
    &:hover {
      color: #D53790;
    }
  }

  .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
  }

  .navbar-collapse {
    .nav-link {
      color: #fff !important;
    }
  }
`;

export default function App() {
  return (
    <Styles>
      <Container>
        <Navbar collapseOnSelect expand="sm">
          <Navbar.Brand href=".">
            <img src="metabadges.png" width="" height="38" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto navbarfont navbar-header nav-links">
              <Nav.Link className="nav-links" href="#Vault">Visit OSIS</Nav.Link>
              <Nav.Link className="nav-links" href="#NFTs">Visit Staking Portal</Nav.Link>
              <Nav.Link className="nav-links" href="https://osisplatform.com/login" target="_blank" rel="noreferrer">Get MetaBadges</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Styles>
  );
}
