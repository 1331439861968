import './App.css';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useEffect, useState } from 'react'
import axios from 'axios';
import VAULTABI from './VAULTABI.json';
import { NFTCONTRACT, STAKINGCONTRACT, moralisapi, nftpng } from './config';
import Web3Modal from "web3modal";
import Web3 from "web3";

var web3 = null;
var account = null;
var vaultcontract = null;
var provider = null;
const gasOptions = { gasPrice: 25854963264, gasLimit: 500000 };

const moralisapikey = "JwcyI3kFcZIx9M9Psom0b0d2ahdb9VxmSs80MnYU43bbwX803Jufh0XzqYKbXzyg";
const providerOptions = {
};

const web3Modal = new Web3Modal({
    network: "mainnet",
    theme: "dark",
    cacheProvider: false,
    providerOptions
});

export default function NFT() {
    const [apicall, getNfts] = useState([])
    const [nftstk, getStk] = useState([])
    const [loadingState, setLoadingState] = useState('not-loaded')
    const [stakeLoading, setStakeLoading] = useState({});
    const [unstakeLoading, setUnstakeLoading] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function initContract() {
            provider = await web3Modal.connect();
            web3 = new Web3(provider);
            await provider.request({ method: 'eth_requestAccounts' });
            const accounts = await web3.eth.getAccounts();
            account = accounts[0];
            vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
            callApi();
        }
        initContract();
    }, []);

    const switchNetwork = async (chainId) => {
        if (![137, '0x89', '137'].includes(chainId)) {
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x89' }],
                });
                await callApi();
                return { msg: 'Change Network Successfull' };
            } catch (e) {
                console.log('error switchNetwork', e);
            }
        }
    }

    async function getNextNftPage(cursor) {
        let config = { 'X-API-Key': moralisapikey, 'accept': 'application/json' };
        return await axios.get((moralisapi + `/nft/${NFTCONTRACT}/owners?chain=polygon&format=decimal&limit=100&cursor=${cursor}`), { headers: config });
    }

    async function callApi() {
        provider = await web3Modal.connect();
        provider.on('chainChanged', switchNetwork);
        web3 = new Web3(provider);
        await provider.request({ method: 'eth_requestAccounts' });
        var accounts = await web3.eth.requestAccounts();
        account = accounts[0];
        switchNetwork(provider.chainId);
        vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);

        setIsLoading(true); // Set loading to true

        const nfts = [];
        let cursor = '';

        do {
            const nextPageRes = await getNextNftPage(cursor);
            cursor = nextPageRes.data.cursor;
            nfts.push(...nextPageRes.data.result);
        } while (cursor);

        console.log("total nfts: ", nfts.length);

        const apicall = await Promise.all(nfts.map(async i => {
            let item = {
                tokenId: i.token_id,
                holder: i.owner_of,
                wallet: account,
            }
            return item;
        }));

        const stakednfts = await vaultcontract.methods.tokensOfOwner(account).call()
            .then(id => {
                return id;
            });

        const nftstk = await Promise.all(stakednfts.map(async tokenId => {
            return {
                tokenId: parseInt(tokenId),
            };
        }));

        console.log("Staked NFTs: ", nftstk);

        getNfts(apicall);
        getStk(nftstk);
        setLoadingState('loaded');
        setIsLoading(false); // Set loading to false
    }
    console.log('check', { loadingState, apicall, nftstk });
    if (loadingState === 'loaded' && !apicall.length) {
        return (<h1 className="text-3xl center">Genesis MetaBadges<br></br>not found</h1>)
    }
    console.log({ stakeLoading, unstakeLoading })

    async function stakeall() {
        setStakeLoading({ all: true });
        const nftIds = apicall.map(nft => nft.tokenId);
        await vaultcontract.methods.stake(nftIds).send({ from: account, ...gasOptions });
        setTimeout(async () => {
            await callApi();
            setStakeLoading({ all: false });
        }, 22000);
    }
    async function unstakeall() {
        setUnstakeLoading({ all: true });
        const stakedNftIds = nftstk.map(nft => nft.tokenId);
        await vaultcontract.methods.unstake(stakedNftIds).send({ from: account, ...gasOptions });
        setTimeout(async () => {
            await callApi();
            setUnstakeLoading({ all: false });
        }, 22000);
    }
    return (
        <div className='container mb-4 '>
            <div className="container ">
                {isLoading ? (
                    <div className="mb-3 mt-3 ">
                        <div className="row items px-5 pt-1">
                            <h3 className="center progress-title">Manifesting...</h3>
                            <div className="progress progress-striped">
                                <div className="nftportal progress-bar"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='container mb-4 '>
                {/* <div className="center mt-3 ml-3 ">
                    <Button
                        style={{
                            marginRight: "10px",
                            backgroundColor: "#D53790",
                            borderRadius: "100px",
                            borderColor: "#D4388D",
                            color: "#ffffff",
                            fontFamily: "Avenir",
                            fontWeight: "800",
                            fontSize: "18px",
                        }}
                        onClick={stakeall}
                    >
                        {stakeLoading.all ? 'Staking All...' : 'Stake All'}
                    </Button>
                    <Button
                        style={{
                            marginLeft: "10px",
                            backgroundColor: "#4AC1E8",
                            borderRadius: "100px",
                            borderColor: "#4AC1E8",
                            color: "#ffffff",
                            fontFamily: "Avenir",
                            fontWeight: "800",
                            fontSize: "18px"
                        }}
                        onClick={unstakeall}
                    >
                        {unstakeLoading.all ? 'Unstaking All...' : 'Unstake All'}
                    </Button>
                </div> */}
                    </div>
                )}
                <div className="row items px-5 pt-1">
                    <div className="ml-3 mr-3 " style={{ display: "inline-grid", gridColumnEnd: "auto", gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", columnGap: "10px" }}>
                        {apicall.map((nft, i) => {
                            const owner = nft.wallet.toLowerCase();
                            const holder = nft.holder.toLowerCase();
                            if (owner.indexOf(holder) !== -1) {
                                async function stakeit() {
                                    setStakeLoading({ [i]: true });
                                    await vaultcontract.methods.stake([nft.tokenId]).send({ from: account, ...gasOptions });
                                    setTimeout(async () => {
                                        await callApi();
                                        setStakeLoading({ [i]: false });
                                    }, 22000);
                                }
                                return (
                                    <div className="container card nft-card mt-3 mb-3" key={i} >
                                        <h5 className="mb-0 ms-2 mt-3">GEN1<br></br>MetaBadges<br></br> #{nft.tokenId}</h5>
                                        <div className="col image-over d-flex justify-content-end">
                                            <img className="card-img-top ms-3 pe-1 mt-3" src={nftpng + nft.tokenId + '.png'} alt="" />
                                        </div>
                                        <div className="card-caption col-12 p-0">
                                            <div className="card-body">
                                                <h5 className="mb-0 mt-2 center"><p style={{ color: "#252F5C", fontWeight: "500", fontSize: "18px" }}>Ready to Stake</p></h5>
                                                <div className="card-bottom d-flex justify-content-between">
                                                    <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                                                    <Button className="btn col" style={{ backgroundColor: "#D53790", borderRadius: "100px", borderColor: "#D4388D", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                                        onClick={stakeit}>{stakeLoading[i] ? 'Refreshing...' : 'Stake it'}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            return null;
                        })}
                        {nftstk.map((nft, i) => {
                            async function unstakeit() {
                                setUnstakeLoading({ [i]: true });
                                await vaultcontract.methods.unstake([nft.tokenId]).send({ from: account, ...gasOptions });
                                setTimeout(async () => {
                                    await callApi();
                                    setUnstakeLoading({ [i]: false });
                                }, 22000);
                            }
                            return (
                                <div className="container card stakedcard mt-3 mb-3" key={i} >
                                    <h5 className="mb-0 ms-2 mt-3">GEN1<br></br>MetaBadges<br></br> #{nft.tokenId}</h5>
                                    <div className="col image-over d-flex justify-content-end">
                                        <img style={{ position: 'absolute', top: '0.05rem', width: '50px' }} src='metabadges.png' width="" height="" alt="" ></img>
                                        <img className="card-img-top ms-3 pe-1 mt-3" src={nftpng + nft.tokenId + '.png'} alt="" />
                                    </div>
                                    <div className="card-caption col-12 p-0">
                                        <div className="card-body">
                                            <h5 className="mb-0 mt-2 center"><p style={{ color: "#FFFFFF", fontWeight: "500", fontSize: "18px" }}>Currently Staked</p></h5>
                                            <div className="card-bottom d-flex justify-content-between">
                                                <input key={i} type="hidden" id='stakeid' value={nft.tokenId} />
                                                <Button className="col" style={{ backgroundColor: "transparent", borderRadius: "100px", borderColor: "#ffffff", color: "#ffffff", fontFamily: "Avenir", fontWeight: "800", fontSize: "18px" }}
                                                    onClick={unstakeit}>{unstakeLoading[i] ? 'Refreshing...' : 'Unstake it'}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='center mt-3 ml-3 '>
                <img src="polygon.png" width={'20%'} height="" alt="" ></img>
            </div>
        </div>
    )
}
